import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import attendanceStore from '@/store/modules/attendanceStore';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { SearchObj, SortObj} from '@/Model/model';

@Component
export default class AttendanceDisplayColumns extends Vue {

    public displayColumn = [{name: '', display: false}];
    public displayAll: boolean = true;
    public dynamicHeight: string = '';
    active: number = -1;
   dropdownElement:any='';
   public activeLetter = '';
   searchElementId: number = -1;
   counter:number = 0;
   activeElement:any='';
   callToggle:boolean=false;
   public tabPress: boolean=false;
   public enterPress: boolean=false;

    @Prop()
    showSettings!: boolean;

    @Prop()
    filteredColumns!: any;

    @Prop()
    tabSelected!: string;

    @Prop()
    tabSelectedValue!: string;

    @Prop()
    searchValue!: string;

    @Prop()
    firstNameSorted!: boolean;

    @Prop()
    lastNameSorted!: boolean;

    @Prop()
    currentSortedColumn!: string;

    @Prop()
    currentSortOrder!: string;
            
    @Prop()
    searchField!:string;

    @Prop()
    currentSearchingDay!: string;

    @Prop()
    weekDay!: string;

    @Watch('filteredColumns', {deep: true, immediate:true})
    updateFilteredOptions(val:any) {
      this.displayColumn = val;
    }

    get checkInTableHeaders(){
      return attendanceStore.checkInHeaders;
    }

    get checkOutTableHeaders(){
      return attendanceStore.checkOutTableHeaders;
    }

    get weekTransporation() {
      return attendanceStore.weekTransporation;
    }

    getFiltersFromStorage(){
      let programId : any = APP_UTILITIES.getCookie('programId');
      programId =JSON.parse(programId);
      let siteId : any = APP_UTILITIES.getCookie('siteId');
      siteId = JSON.parse(siteId);
      if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day'){
        /* istanbul ignore else */
        if(localStorage.getItem('CheckinGearSettingsData')){
          let checInHeaders: any = localStorage.getItem('CheckinGearSettingsData');
          checInHeaders = JSON.parse(checInHeaders);
          /* istanbul ignore else */
          if(checInHeaders.hasOwnProperty([programId])){
            /* istanbul ignore else */
            if(checInHeaders[programId].hasOwnProperty([siteId])){
              this.displayColumn = checInHeaders[programId][siteId];
            }
          }
        }
      }
      else if(this.tabSelected == 'Check-out'){
        /* istanbul ignore else */
        if(localStorage.getItem('CheckOutGearSettingsData')){
          let checkOutHeaders: any = localStorage.getItem('CheckOutGearSettingsData');
          checkOutHeaders = JSON.parse(checkOutHeaders);
          /* istanbul ignore else */
          if(checkOutHeaders.hasOwnProperty([programId])){
            /* istanbul ignore else */
            if(checkOutHeaders[programId].hasOwnProperty([siteId])){
              this.displayColumn = checkOutHeaders[programId][siteId];
            }
          }
        }
      } if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week'){
        /* istanbul ignore else */
        if(localStorage.getItem('CheckinWeekGearSettingsData')){
          let checInWeekHeaders: any = localStorage.getItem('CheckinWeekGearSettingsData');
          checInWeekHeaders = JSON.parse(checInWeekHeaders);
          /* istanbul ignore else */
          if(checInWeekHeaders.hasOwnProperty([programId])){
            /* istanbul ignore else */
            if(checInWeekHeaders[programId].hasOwnProperty([siteId])){
              this.displayColumn = checInWeekHeaders[programId][siteId];
            }
          }
        }
      }
    }
    get search(){
      return attendanceStore.attendanceSearchData;
    }

    /* instanbul ignore next */
    mounted() {
      this.getFiltersFromStorage();
      this.checkColumns();
        
      const selectAllElement:any = document.querySelector('#select-all');
      selectAllElement && selectAllElement.classList.add('dropbtn-selected');
      selectAllElement && selectAllElement.focus();
      this.counter++;
    }

    checkColumns(column?: any) {
      let isSelected = true;
      let display: any;
      const key = '';
      const tempCheckinHeaders = JSON.parse(JSON.stringify(this.checkInTableHeaders));
      const tempCheckOutHeaders = JSON.parse(JSON.stringify(this.checkOutTableHeaders));
      this.displayColumn.forEach(column => { 
        if (!column.display) {
          isSelected = false;
        }
      });
        
      if(column && (column.name == 'Check-in' || column.name == 'Check-out')){
        display = column.display;
        this.$emit('changeDisplayDayAttendanceColumn', column.display);
      }
        
      else if(column && (column.key=='Su' || column.key=='M' || column.key == 'T' || column.key == 'W' || column.key == 'Th' || column.key == 'F' || column.key == 'S')){
        this.$emit('changeDisplayWeekAttendanceColumn', column.display, column.key);
      }
        
      else if(this.tabSelectedValue == 'Week' && column && column.name=='Transportation'){
        attendanceStore.mutateWeekTransportation(column.display);
      }
        
      if(this.tabSelected == 'Check-in'){
        tempCheckinHeaders.forEach((header: any) => {
          if(column && header.name == column.name){
            header.display = column.display;
          }
        });
        if(this.tabSelectedValue == 'Day'){
          attendanceStore.mutateCheckinHeaders(tempCheckinHeaders);
          attendanceStore.mutateCheckInGearIconSetting(this.displayColumn);
        }
        else if (this.tabSelectedValue == 'Week') {
          attendanceStore.mutateCheckinHeaders(tempCheckinHeaders);
          attendanceStore.mutateCheckInWeekGearIconSetting(this.displayColumn);
        }
      }
        
      else if(this.tabSelected == 'Check-out'){ 
        tempCheckOutHeaders.forEach((header: any) => {
          if(column && header.name == column.name){
            header.display = column.display;
          }
        });
        attendanceStore.mutateCheckOutHeaders(tempCheckOutHeaders);
        attendanceStore.mutateCheckOutGearIconSetting(this.displayColumn);
      }
      this.displayAll = isSelected;
        
      this.removeFilterOnColumnHide(column);
      this.$emit('filterColumns', this.displayColumn);
      this.setHeight();
    }

    toggleAllColumns(event:any,eventName?:string) {
      if(event && event.keyCode == 32 && this.callToggle || eventName && eventName == 'click'){
        let searchObj: SearchObj = {
          'searchField':  this.searchField
            ? this.searchField
            : '',
          'search' : this.searchValue
            ? this.searchValue
            : '',
          'weekDay':''
        };
        let sortObj : SortObj = {
          'sortField' : this.currentSortedColumn
            ? this.currentSortedColumn
            : '',
          'sortDir' : this.currentSortOrder
            ? this.currentSortOrder
            : 'asc',
          'weekDay':''
        };
        const tempHeaders = JSON.parse(JSON.stringify(this.checkInTableHeaders));
        const tempCheckOutHeaders = JSON.parse(JSON.stringify(this.checkOutTableHeaders));
        const tempCheckInWeekHeaders = JSON.parse(JSON.stringify(this.displayColumn));
        if (this.displayAll) {
          this.displayColumn.forEach(column => {
            column.display = false;
          });
          if(this.tabSelected=='Check-in' && this.tabSelectedValue == 'Day'){
            tempHeaders.forEach((header: any) => {
              header.display = false;
            });
            attendanceStore.mutateCheckinHeaders(tempHeaders);
            attendanceStore.mutateCheckInGearIconSetting(tempHeaders);
          }

          else if(this.tabSelected == 'Check-out'){
            tempCheckOutHeaders.forEach((header: any) => {
              header.display = false;
            });
            attendanceStore.mutateCheckOutHeaders(tempCheckOutHeaders);
            attendanceStore.mutateCheckOutGearIconSetting(tempCheckOutHeaders);
          }

          else if(this.tabSelected=='Check-in' && this.tabSelectedValue == 'Week'){
            tempCheckInWeekHeaders.forEach((header: any) => {
              header.display = false;
            });
            attendanceStore.mutateCheckinHeaders(tempCheckInWeekHeaders);
            attendanceStore.mutateWeekTransportation(false);
            attendanceStore.mutateCheckInWeekGearIconSetting(tempCheckInWeekHeaders);
          }
          this.displayAll = false;

          /* istanbul ignore else */
          if(this.searchField || this.currentSortOrder || this.currentSortedColumn || this.searchValue){
            searchObj={
              'searchField': '',
              'search' : '',
              'weekDay':''  
            };
            sortObj={
              'sortField' : this.currentSortedColumn == 'lastName'
                ? 'lastName'
                : 'firstName',
              'sortDir': this.currentSortOrder == 'desc'
                ? 'desc'
                : 'asc',
              'weekDay' : ''
            };
            const data = {
              searchObj:searchObj,
              sortObj:sortObj
            };
                
            if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day'){
              attendanceStore.mutateCheckInSortingPersistance(sortObj);
              attendanceStore.mutateCheckInSearchingPersistance(searchObj);
            }
            else if(this.tabSelected == 'Check-out'){
              attendanceStore.mutateCheckOutSortingPersistance(sortObj);
              attendanceStore.mutateCheckOutSearchingPersistance(searchObj);
            }
            else if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Week'){
              attendanceStore.mutateCheckInWeekSortingPersistance(sortObj);
              attendanceStore.mutateCheckInWeekSearchingPersistance(searchObj);
            }
            this.$emit('prepareScholarAttendanceData',data);
          }
        }
        else {
          this.displayColumn.forEach(column => {
            column.display = true;
          });
          if(this.tabSelected=='Check-in' && this.tabSelectedValue=='Day'){
            tempHeaders.forEach((header: any) => {
              header.display = true;
            });
            attendanceStore.mutateCheckinHeaders(tempHeaders);
            attendanceStore.mutateCheckInGearIconSetting(tempHeaders);
                
          }

          if(this.tabSelected == 'Check-out'){
            tempCheckOutHeaders.forEach((header: any) => {
              header.display = true;
            });
            attendanceStore.mutateCheckOutHeaders(tempCheckOutHeaders);
            attendanceStore.mutateCheckOutGearIconSetting(tempCheckOutHeaders);
          }
          else if(this.tabSelected=='Check-in' && this.tabSelectedValue == 'Week'){
            tempCheckInWeekHeaders.forEach((header: any) => {
              header.display = true;
            });
            attendanceStore.mutateCheckinHeaders(tempCheckInWeekHeaders);
            attendanceStore.mutateWeekTransportation(true);
            attendanceStore.mutateCheckInWeekGearIconSetting(tempCheckInWeekHeaders);
          }
          this.displayAll = true;
        }
        this.$emit('changeAllAttendanceColumns', this.displayAll);
        this.$emit('filterColumns', this.displayColumn);
        this.setHeight();
      }
      else{
        return event.preventDefault();
      }
    }

    setHeight() {
      const leftTableEle = document.getElementById('wrap');
      const tableHeight = leftTableEle && leftTableEle.offsetHeight;
      this.dynamicHeight = tableHeight && tableHeight.toString() + 'px' || '';
      this.$emit('setHeight', this.dynamicHeight);
    }

    hoverState(type:string, index:number ){
      this.dropdownElement = document.querySelector('#dropdown-menu');
      const selectAllElement = document.querySelector('#select-all');
      if(type == 'listItem'){
        const dropdownMenuItems = this.dropdownElement && this.dropdownElement.children;
        this.active = index;
        if(this.active==0){
          dropdownMenuItems && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
          dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
            if (index != this.active) {
              dropdownMenuItems[index].classList.remove('dropbtn-selected');
            }
          });
          selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
        }
        else if(this.active && this.active>-1){
          dropdownMenuItems && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
          dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
            if (index != this.active) {
              dropdownMenuItems[index].classList.remove('dropbtn-selected');
            }
          });
          selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
        }
      }
      else if(type == 'selectAll'){
        selectAllElement && selectAllElement.classList.add('dropbtn-selected');
        const dropdownMenuItems = this.dropdownElement.children;
        this.active = index;
        dropdownMenuItems.forEach((drop: any, index: number) => {
          dropdownMenuItems[index].classList.remove('dropbtn-selected');
        });
      }
     
    }
  
    removeClass(){
      this.active = -1;
      this.counter =  0;
      const selectAllElement = document.querySelector('#select-all');
      const dropdownMenuItems = this.dropdownElement && this.dropdownElement.children;
      dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
        if (index != this.active) {
          dropdownMenuItems[index].classList.remove('dropbtn-selected');
        }
      });
      selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
    }

    onDropdownOpen(e:any,column?:any){
      if(e.keyCode == 9){
        this.tabPress = true;
        this.removeClass();
        this.$emit('tab',this.tabPress);
      }
      else if(e.keyCode == 13){
        this.enterPress = true;
        this.removeClass();
        this.$emit('enter',this.enterPress);
      }
      this.dropdownElement = document.querySelector('#dropdown-menu');
      const dropdownMenuItems = this.dropdownElement && this.dropdownElement.children;
      if(this.showSettings){
        const input = String.fromCharCode(e.keyCode);
        const selectAllElement = document.querySelector('#select-all');
        if(e.keyCode == 40){
          e.preventDefault();
          if(this.active == -1 && this.counter == 0){
            selectAllElement && selectAllElement.classList.add('dropbtn-selected');
            this.counter++;
          }
          else if(this.active < this.dropdownElement.children.length - 1){
            this.active = this.active+1;
            dropdownMenuItems[this.active].classList.add('dropbtn-selected');
            dropdownMenuItems.forEach((drop:any,index: number)=>{
              if(index!=this.active){
                dropdownMenuItems[index].classList.remove('dropbtn-selected');
              }
            });
            selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
            dropdownMenuItems[this.active].scrollIntoView({behavior: 'smooth', block: 'end'});
          }
        }
        else if(e.keyCode == 38){
          e.preventDefault();
          if(this.active == -1 && this.counter == 0){
            selectAllElement && selectAllElement.classList.add('dropbtn-selected');
            this.counter++;
          }
          else if(this.active > 0){
            this.active--;
            if(this.active != -1){
              dropdownMenuItems[this.active].classList.add('dropbtn-selected');
              dropdownMenuItems.forEach((drop:any,index: number)=>{
                if(index!=this.active){
                  dropdownMenuItems[index].classList.remove('dropbtn-selected');
                }
              });
              selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
              dropdownMenuItems[this.active].scrollIntoView({behavior: 'smooth', block: 'end'});
            }
          }
          else if(this.active == 0){
            this.active = -1;
            selectAllElement && selectAllElement.classList.add('dropbtn-selected');
            dropdownMenuItems.forEach((drop: any, index: number) => {
              if (index != this.active) {
                dropdownMenuItems[index].classList.remove('dropbtn-selected');
              }
            });
            selectAllElement && selectAllElement.scrollIntoView({behavior: 'smooth', block: 'end'});
          }
        }
        else if (/[a-zA-Z0-9]/.test(input)) {
          let iterationCount:number = 1;
          let i:number = this.active;
          selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
          while(iterationCount!=this.dropdownElement.children.length){
            if(++i==this.dropdownElement.children.length) {
              i=0;
            }
            const val = dropdownMenuItems[i].innerText.charAt(0).toLowerCase();
            const inp = input.toLowerCase();
            if(val == inp){
              const dropdownMenuItems = this.dropdownElement.children;
              this.active = i;
              dropdownMenuItems[this.active].classList.add('dropbtn-selected');
              dropdownMenuItems.forEach((drop: any, index: number) => {
                if (index != this.active) {
                  dropdownMenuItems[index].classList.remove('dropbtn-selected');
                }
              });
              dropdownMenuItems[this.active].scrollIntoView({behavior: 'smooth', block: 'end'});
              break;
            }              
            iterationCount++;
          }
        }
        if(e.keyCode == 32){
          e.preventDefault();
          if(this.active == 0){
            this.displayColumn[this.active].display = !this.displayColumn[this.active].display;
            this.checkColumns(this.displayColumn[this.active]);
          }
          else
          if(this.active > -1){
            this.displayColumn[this.active].display = !this.displayColumn[this.active].display;
            this.checkColumns(this.displayColumn[this.active]);
          }
          else if(this.active == -1 || this.active == -2 ){
            this.active = this.active == -2
              ? -1
              : -1;
            this.callToggle = true;
            this.toggleAllColumns(e);
            this.callToggle=false;
          }
        }
      }
    }

    removeFilterOnColumnHide(column: any){
      /* istanbul ignore else */
      if(column && !column.display){
        let searchObj: SearchObj = {
          'searchField':  this.searchField
            ? this.searchField
            : '',
          'search' : this.searchValue
            ? this.searchValue
            : '',
          'weekDay':''
        };
        let sortObj : SortObj = {
          'sortField' : this.currentSortedColumn
            ? this.currentSortedColumn
            : '',
          'sortDir' : this.currentSortOrder
            ? this.currentSortOrder
            : 'asc',
          'weekDay':''
        };
        let searchAndSortdata:any={};

        if(this.tabSelected == 'Check-in' && this.tabSelectedValue == 'Day'){
          const tempSearch = this.search;
          if(((this.currentSortedColumn == 'arrivalPlan' || this.searchField == 'ArrivalPlan') && column.name=='Transportation') || ((this.currentSortedColumn=='Attendance' || this.searchField == 'Attendance') && column.name=='Check-in')){
            searchObj = {
              'searchField': '',
              'search' : ''
            };

            sortObj = {
              'sortField' : 'firstName',
              'sortDir' : 'asc'
            };

            searchAndSortdata={    
              searchObj : searchObj,                
              sortObj : sortObj 
            };
                    
            attendanceStore.mutateCheckInSortingPersistance(sortObj);
            attendanceStore.mutateCheckInSearchingPersistance(searchObj);
            this.$emit('prepareScholarAttendanceData',searchAndSortdata);
            if(column && (column.name == 'Check-in' || column.name == 'Check-out')){
              this.$emit('changeDisplayDayAttendanceColumn', column.display);
            }
          }
        }
        else if(this.tabSelected == 'Check-out'){
          if(((this.currentSortedColumn == 'dismissalPlan' || this.searchField == 'Dismissalplan') && column.name=='Transportation') || ((this.currentSortedColumn=='Attendance' || this.searchField == 'Attendance') && column.name=='Check-out')){
            searchObj = {
              'searchField': '',
              'search' : ''
            };

            sortObj = {
              'sortField' : 'firstName',
              'sortDir' : 'asc'
            };

            searchAndSortdata={    
              searchObj : searchObj,                
              sortObj : sortObj 
            };
                    
            attendanceStore.mutateCheckOutSortingPersistance(sortObj);
            attendanceStore.mutateCheckOutSearchingPersistance(searchObj);
            this.$emit('prepareScholarAttendanceData',searchAndSortdata);
          }
        }
        else if(this.tabSelected == 'Check-in' && this.tabSelectedValue=='Week'){
          if(((this.currentSortedColumn == 'arrivalPlan' || this.searchField == 'ArrivalPlan') && column.name=='Transportation') || ((this.weekDay == column.key) && column.name != 'Transportation')){
            searchObj = {
              'searchField': '',
              'search' : '',
              'weekDay': ''
            };

            sortObj = {
              'sortField' : 'firstName',
              'sortDir' : 'asc',
              'weekDay': ''
            };

            searchAndSortdata={    
              searchObj : searchObj,                
              sortObj : sortObj 
            };
                    
            attendanceStore.mutateCheckInWeekSortingPersistance(sortObj);
            attendanceStore.mutateCheckInWeekSearchingPersistance(searchObj);
            this.$emit('prepareScholarAttendanceData',searchAndSortdata);
          }
        }
      }
    }
}